const light = Object.freeze({
    primary: '#283582',
    secondary: '#2b89c9',
    navBackground: '#FFFFFF',
    tileBackground: '#FFFFFF',
    appBackground: '#F5F5F5',
    inputBackground: '#FFFFFF',
    logoColor: '#FFFFFF',
    logoFontColor: '#2C3587',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
})

const dark = Object.freeze({
    primary: '#283582',
    secondary: '#2b89c9',
    navBackground: '#2C2C2C',
    tileBackground: '#2C2C2C',
    appBackground: '#1B1B1B',
    inputBackground: '#222222',
    logoColor: '#FFFFFF',
    logoFontColor: '#FFFFFF',
    error: '#F99C9D',
    info: '#90C9F9',
    success: '#A5D6A7',
    warning: '#FFCB7F',
})

const props = Object.freeze({
    headerLogoWidth: 150,
    headerLogoSrc: require('@/assets/custom/images/headers/living-header.jpg')
})

module.exports = Object.freeze({
    light,
    dark,
    props
})

